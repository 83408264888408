// src/components/SciencePage.js
import React from 'react';
import { Link } from 'react-router-dom';
import './shared-styles.css';

const SciencePage = () => {
  return (
    <div className="homepage-container">
      <header className="header">
        <h1>Welcome to Edu 2U</h1>
        <p>Unlock your potential with AI-driven solutions, transforming learning, creativity, and productivity for students and professionals.</p>
      </header>

      <section className="tools-section">
        <div className="tool-block">
          <div className="tool-icon">🧬</div> {/* Biology Icon */}
          <h2>Biology</h2>
          <p>Dive into the study of life, from cells to ecosystems, exploring genetics, evolution, and physiology.</p>
          <Link to="/subject/Biology" className="tool-link">Biology</Link>
        </div>

        <div className="tool-block">
          <div className="tool-icon">⚗️</div> {/* Chemistry Icon */}
          <h2>Chemistry</h2>
          <p>Explore the building blocks of matter, chemical reactions, and the periodic table in Chemistry.</p>
          <Link to="/subject/Chemistry" className="tool-link">Chemistry</Link>
        </div>

        <div className="tool-block">
          <div className="tool-icon">🌍</div> {/* Earth Science Icon */}
          <h2>Earth Science</h2>
          <p>Understand the Earth, from its layers to the atmosphere, weather patterns, and geological processes.</p>
          <Link to="/subject/EarthScience" className="tool-link">Earth Science</Link>
        </div>

        <div className="tool-block">
          <div className="tool-icon">⚛️</div> {/* Physics Icon */}
          <h2>Physics</h2>
          <p>Learn about the fundamental laws of nature, including motion, energy, and the forces that govern the universe.</p>
          <Link to="/subject/Physics" className="tool-link">Physics</Link>
        </div>
      </section>

      <section className="tools-section">
        <div className="tool-block">
          <div className="tool-icon">🔬</div> {/* Environmental Science Icon */}
          <h2>Environmental Science</h2>
          <p>Explore the relationship between humans and the environment, focusing on sustainability, ecosystems, and conservation.</p>
          <Link to="/subject/EnvironmentalScience" className="tool-link">Environmental Science</Link>
        </div>

        <div className="tool-block">
          <div className="tool-icon">🧠</div> {/* Human Anatomy and Physiology Icon */}
          <h2>Human Anatomy & Physiology</h2>
          <p>Study the human body and how its systems work together to maintain health and function.</p>
          <Link to="/subject/HumanAnatomy" className="tool-link">Human Anatomy & Physiology</Link>
        </div>

        <div className="tool-block">
          <div className="tool-icon">🔭</div> {/* Telescope Icon for Astronomy */}
          <h2>Astronomy</h2>
          <p>Delve into the universe, studying stars, planets, galaxies, and the cosmos in Astronomy.</p>
          <Link to="/subject/Astronomy" className="tool-link">Astronomy</Link>
        </div>

        <div className="tool-block">
          <div className="tool-icon">🌋</div> {/* Geology Icon */}
          <h2>Geology</h2>
          <p>Explore the Earth’s physical structure, its history, and the processes that shape it over time in Geology.</p>
          <Link to="/subject/Geology" className="tool-link">Geology</Link>
        </div>
      </section>

      <section className="tools-section">
        <div className="tool-block">
          <div className="tool-icon">🧫</div> {/* Microbiology Icon */}
          <h2>Microbiology</h2>
          <p>Study microorganisms like bacteria, viruses, fungi, and their roles in health, disease, and the environment.</p>
          <Link to="/subject/Microbiology" className="tool-link">Microbiology</Link>
        </div>

        <div className="tool-block">
          <div className="tool-icon">🌿</div> {/* Ecology Icon */}
          <h2>Ecology</h2>
          <p>Dive into Ecology to learn about the relationships between living organisms and their environments, ecosystems, and the flow of energy.</p>
          <Link to="/subject/Ecology" className="tool-link">Ecology</Link>
        </div>

        <div className="tool-block">
          <div className="tool-icon">🧬</div> {/* Genetics Icon */}
          <h2>Genetics</h2>
          <p>Understand how traits are inherited and explore the science of genes, DNA, and heredity in Genetics.</p>
          <Link to="/subject/Genetics" className="tool-link">Genetics</Link>
        </div>

        <div className="tool-block">
          <div className="tool-icon">🦠</div> {/* Biotechnology Icon */}
          <h2>Biotechnology</h2>
          <p>Explore how biological systems and living organisms are applied in technology and innovation in Biotechnology.</p>
          <Link to="/subject/Biotechnology" className="tool-link">Biotechnology</Link>
        </div>
      </section>
    </div>
  );
};

export default SciencePage;
