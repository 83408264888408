import React, { useState, useEffect, useMemo, useCallback } from 'react';
import './shared-styles.css';
import { MathJax, MathJaxContext } from 'better-react-mathjax';
import { Line, Scatter } from 'react-chartjs-2';
import { useParams } from 'react-router-dom';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,  
  Legend
} from 'chart.js';
import subjectData from './SubjectData';  // External subject data file

// Register necessary components for Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,  
  Legend
);

function SubjectPage() {
  const { subjectKey } = useParams();  // Get subjectKey from URL

  // Wrap the initialization of 'subject' in useMemo to avoid unnecessary recalculations
  const subject = useMemo(() => subjectData[subjectKey] || {}, [subjectKey]);

  const [chapters, setChapters] = useState([]);
  const [topics, setTopics] = useState([]);
  const [selectedChapter, setSelectedChapter] = useState('');
  const [selectedTopic, setSelectedTopic] = useState('');
  const [content, setContent] = useState('');
  const [graphData, setGraphData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [satQuestions, setSatQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [graphType, setGraphType] = useState('');

  const config = {
    tex: {
      inlineMath: [['$', '$'], ['\\(', '\\)']],
      displayMath: [['$$', '$$'], ['\\[', '\\]']],
    },
    options: {
      processEscapes: true,
    },
    displayAlign: "left",
    displayIndent: "0",  // Ensure no extra indentation
  };

  const chapterTopics = useMemo(() => subject.chapterTopics || {}, [subject]);

  const resetStates = useCallback(() => {
    setContent('');
    setGraphData(null);
    setErrorMessage('');
    setSatQuestions([]);
    setCurrentQuestionIndex(0);
  }, []);

  useEffect(() => {
    setChapters(Object.keys(chapterTopics));
  }, [chapterTopics]);

  useEffect(() => {
    if (selectedChapter) {
      setTopics(chapterTopics[selectedChapter]);
      resetStates();
    }
  }, [selectedChapter, chapterTopics, resetStates]);

  useEffect(() => {
    if (selectedTopic) {
      resetStates();
    }
  }, [selectedTopic, resetStates]);

  const extractGraphFromContent = (content) => {
    const graphPattern = /```json\s*([\s\S]*?)```/;
    const match = content.match(graphPattern);
    if (match) {
      let graphContent = match[1].trim();
      const jsonStartIndex = graphContent.indexOf('{');
      const jsonEndIndex = graphContent.lastIndexOf('}');
      if (jsonStartIndex !== -1 && jsonEndIndex !== -1) {
        const validJsonString = graphContent.substring(jsonStartIndex, jsonEndIndex + 1).trim();
        try {
          const graphJson = JSON.parse(validJsonString);
          return graphJson;
        } catch (e) {
          console.error('Error parsing graph JSON:', e);
        }
      }
    }
    return null;
  };

  const backendURL = process.env.NODE_ENV === 'production'
    ? 'https://algebra2-cb6f8873987a.herokuapp.com'
    : 'http://127.0.0.1:5000';

  const handleSubmit = async () => {
    setLoading(true);
    resetStates();
    try {
      const response = await fetch(`${backendURL}/get_topic_content`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          chapter: selectedChapter,
          topic: selectedTopic,
          subject: subjectKey,
        }),
      });

      const data = await response.json();
      if (data.content) {
        const contentArray = Array.isArray(data.content) ? data.content : [data.content];
        setContent(contentArray.filter(paragraph => paragraph.trim() !== ''));
        const graph = extractGraphFromContent(contentArray.join('\n'));
        if (graph) {
          let chartData;
          if (graph.type === 'line') {
            setGraphType('line');
            chartData = {
              labels: graph.x_values,
              datasets: [
                {
                  label: 'Line Graph',
                  data: graph.y_values,
                  borderColor: 'rgb(75, 192, 192)',
                  fill: false,
                  tension: 0.1,
                },
              ],
            };
          } else if (graph.type === 'scatter') {
            setGraphType('scatter');
            chartData = {
              datasets: [
                {
                  label: 'Scatter Graph',
                  data: graph.x_values.map((x, index) => ({
                    x: x,
                    y: graph.y_values[index]
                  })),
                  backgroundColor: 'rgb(75, 192, 192)',
                },
              ],
            };
          }
          setGraphData(chartData);
        } else {
          console.log('Graph data not available for this topic.');
        }
      } else {
        setErrorMessage('No content available for this topic.');
      }
    } catch (error) {
      setErrorMessage('An error occurred while fetching content. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleGenerateSATQuestions = async () => {
    setLoading(true);
    resetStates();
    try {
      const response = await fetch(`${backendURL}/get_sat_questions`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          chapter: selectedChapter,
          topic: selectedTopic,
          subject: subjectKey,
        }),
      });
      const data = await response.json();
      setSatQuestions(data.questions);
      setCurrentQuestionIndex(0);
    } catch (error) {
      setErrorMessage('Error fetching SAT questions.');
    } finally {
      setLoading(false);
    }
  };

  const handleNextQuestion = () => {
    if (currentQuestionIndex < satQuestions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  };

  const processContent = (text) => {
    if (typeof text !== 'string') return '';
  
    return text
      .replace(/(^|\n)# (.*?)(?=\n|$)/g, '<h1>$2</h1>')
      .replace(/(^|\n)## (.*?)(?=\n|$)/g, '<h2>$2</h2>')
      .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')
      .replace(/_(.*?)_/g, '<em>$1</em>')
      .replace(/\n\n/g, '</p><p>');
  };

  // Render the component conditionally based on whether the subject exists
  return subject.name ? (
    <div className="App" style={{width: '100%'}}>
      <MathJaxContext config={config}>
        <h1 style={{textAlign: 'center'}}>{subject.name}</h1>
        <p style={{ textAlign: 'center', padding: '1rem' }} className="intro-text">{subject.introText}</p>
        <div className="form-container" style={{width: '90%'}}>
          <select value={selectedChapter} onChange={(e) => setSelectedChapter(e.target.value)}>
            <option value="">Select a Chapter</option>
            {chapters.map((chapter, index) => (
              <option key={index} value={chapter}>{chapter}</option>
            ))}
          </select>
          <select value={selectedTopic} onChange={(e) => setSelectedTopic(e.target.value)}>
            <option value="">Select a Topic</option>
            {topics.map((topic, index) => (
              <option key={index} value={topic}>{topic}</option>
            ))}
          </select>

          <div className="button-container">
            <button onClick={handleSubmit} disabled={!selectedChapter || !selectedTopic}>
              Lesson
            </button>
            <button onClick={handleGenerateSATQuestions} disabled={!selectedChapter || !selectedTopic}>
              Quiz
            </button>
          </div>
        </div>

        {loading && <p className="loading" style={{width: '90%'}}>If the page doesn't load or appears incorrect, there might be an issue with the OpenAI API. Please try again. Loading... </p>}
        {errorMessage && <p className="error">{errorMessage}</p>}

        <div className="form-container" style={{width: '90%'}}>
          {Array.isArray(content) && content.map((paragraph, index) => (
            <div key={index}>
              <MathJax>
                <div dangerouslySetInnerHTML={{ __html: processContent(paragraph) }} />
              </MathJax>
            </div>
          ))}

          {satQuestions.length > 0 && (
            <div className="form-container" style={{width: '95%'}}>
              <h2>Quiz on {selectedTopic}</h2>
              <MathJax>
                <div dangerouslySetInnerHTML={{ 
                  __html: processContent(
                    satQuestions[currentQuestionIndex].question.replace(/\n/g, '<br>') 
                  ) 
                }} />
              </MathJax>
              <p><strong>Answer:</strong> 
                <MathJax>
                  <div dangerouslySetInnerHTML={{ 
                    __html: processContent(
                      satQuestions[currentQuestionIndex].answer.replace(' Correct', '').replace('**Correct', '') 
                    ) 
                  }} />
                </MathJax>
              </p>
              {currentQuestionIndex < satQuestions.length - 1 && (
                <button onClick={handleNextQuestion}>Next Question</button>
              )}
            </div>
          )}
        </div>

        {graphData && (
          <div className="graph">
            {graphType === 'scatter' ? (
              <Scatter data={graphData} options={{
                scales: {
                  x: {
                    type: 'linear',
                    position: 'bottom',
                  },
                },
              }} />
            ) : (
              <Line data={graphData} />
            )}
          </div>
        )}
      </MathJaxContext>
    </div>
  ) : (
    <div className="App" style={{width: '100%'}}>
      <h1>Under Construction</h1>
    </div>
  );
}

export default SubjectPage;
