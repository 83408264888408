import React, { useState } from 'react';
import axios from 'axios';
import 'katex/dist/katex.min.css';
import './shared-styles.css';

const apiUrl = 'https://edu2u-b0fcd22669f9.herokuapp.com'; // Replace with your actual Flask backend URL

function EduGen() {
  const [subjectTopic, setSubjectTopic] = useState('');
  const [contentType, setContentType] = useState('Quiz');
  const [generatedContent, setGeneratedContent] = useState('');
  const [loading, setLoading] = useState(false); // Spinner state
  const [error, setError] = useState(null);

  const contentTypes = ['Quiz', 'Lesson'];

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Start spinner
    setError(null);
    setGeneratedContent('');

    let prompt = '';

    if (contentType === 'Quiz') {
      prompt = `Generate a quiz on ${subjectTopic} for students. Include 10 multiple-choice questions with 4 options each and provide detailed explanations for each question.`;
    } else if (contentType === 'Lesson') {
      prompt = `Write a detailed lesson on ${subjectTopic} for students. Explain the topic in depth as a teacher would do, with examples where appropriate.`;
    }

    try {
      const response = await axios.post(
        `${apiUrl}/generate-content`,
        {
          subjectTopic,
          contentType,
          prompt,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      const parsedContent = parseMarkdown(response.data.content);
      setGeneratedContent(parsedContent);
    } catch (error) {
      setError('Something went wrong. Please try again.');
      console.error('Error:', error);
    } finally {
      setLoading(false); // Stop spinner
    }
  };

  const parseMarkdown = (content) => {
    const htmlContent = content
      .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')  // Bold
      .replace(/\*(.*?)\*/g, '<em>$1</em>')  // Italic
      .replace(/\n\s*\n/g, '<br/><br/>')  // Replace multiple line breaks with paragraph breaks
      .replace(/\n/g, ' ')  // Replace single line breaks with a space
      .replace(/<br\/><br\/><br\/>/g, '<br/>')  // Replace triple <br> with a single <br>
      .replace(/A\)/g, '<br/><strong>A)</strong> ')  // Options on new lines
      .replace(/B\)/g, '<br/><strong>B)</strong> ')
      .replace(/C\)/g, '<br/><strong>C)</strong> ')
      .replace(/D\)/g, '<br/><strong>D)</strong> ')
      .replace(/Explanation:/g, '<br/><strong>Explanation:</strong>'); // Explanation header

    return { __html: htmlContent };
  };

  return (
    <div className="App">
      <h1 style={{ textAlign: 'center' }}>Ask Anything</h1>
      <form onSubmit={handleSubmit} className="form" style={{ marginBottom: '20px' }}>
        <div className="form-group" style={{ marginBottom: '20px' }}>
          <label>Subject & Topic:</label>
          <input
            type="text"
            value={subjectTopic}
            onChange={(e) => setSubjectTopic(e.target.value)}
            placeholder="e.g., Math - Geometry, Cloud - AWS Data Storage"
            required
          />
        </div>

        <div className="form-group">
          <label>Content Type:</label>
          <select
            value={contentType}
            onChange={(e) => setContentType(e.target.value)}
            required
          >
            {contentTypes.map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </select>
        </div>
        <button type="submit" disabled={loading} className="submit-button">
          {loading ? 'Creating Content...' : 'Craft My Content'}
        </button>
      </form>

      {loading && <p className="loading">Loading... Please wait.</p>} {/* Spinner here */}

      {error && <p className="error-message">{error}</p>}
      {generatedContent && (
        <div className="generated-content">
          <h2>Here is Your Personalized Study Material</h2>
          <div dangerouslySetInnerHTML={generatedContent} />
        </div>
      )}
    </div>
  );
}

export default EduGen;
