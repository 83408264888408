import React, { useRef, useState } from 'react';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import axios from 'axios';
import './shared-styles.css';

function JobLens() {
  const [jobDescription, setJobDescription] = useState('');
  const [resumes, setResumes] = useState([]);
  const [reports, setReports] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false); // Spinner state

  const reportRefs = useRef([]); // Create a ref array for the reports

  const handleFileUpload = (e) => {
    setResumes(e.target.files);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loading spinner
    setErrorMessage('');
    setReports([]);

    const formData = new FormData();
    formData.append('job_description', jobDescription);
    for (let i = 0; i < resumes.length; i++) {
      formData.append('resumes', resumes[i]);
    }

    try {
      const response = await axios.post('https://joblens-97eeeee433d9.herokuapp.com/analyze', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      const responseData = response.data.reports;
      setReports(responseData);
    } catch (error) {
      setErrorMessage('An error occurred: ' + (error.response?.data?.error || 'Unexpected error. Please try again.'));
    }

    setLoading(false); // Stop loading spinner
  };

  const formatReportWithHeadings = (reportContent) => {
    const lines = reportContent.replace(/\*\*/g, '').split('\n');
    const formattedReport = lines.map((line, index) => {
      const [heading, ...rest] = line.split(':');
      const content = rest.join(':').trim();
      if (heading && content) {
        return (
          <div key={index}>
            <h3 style={{ color: '#007bff', marginBottom: '5px' }}>{heading}:</h3>
            <p style={{ color: '#495057', marginLeft: '10px', fontSize: '18px' }}>{content}</p>
          </div>
        );
      }
      return null;
    });
    return formattedReport;
  };

  const downloadPDF = (index, candidateName) => {
    const input = reportRefs.current[index];
    const button = input.querySelector('.pdf-button');
    if (button) {
      button.style.display = 'none';
    }

    html2canvas(input, {
      scale: 2,
      useCORS: true,
      backgroundColor: '#fff',
    }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF({
        orientation: 'portrait',
        unit: 'pt',
        format: 'a4',
      });

      const imgWidth = 595.28;
      const pageHeight = 841.89;
      const margin = 40;
      const imgHeight = (canvas.height * (imgWidth - 2 * margin)) / canvas.width;
      let heightLeft = imgHeight;

      let position = margin;
      pdf.addImage(imgData, 'PNG', margin, position, imgWidth - 2 * margin, imgHeight);
      heightLeft -= pageHeight - 2 * margin;

      while (heightLeft > 0) {
        position = heightLeft - imgHeight + margin;
        pdf.addPage();
        pdf.addImage(imgData, 'PNG', margin, position, imgWidth - 2 * margin, imgHeight);
        heightLeft -= pageHeight - 2 * margin;
      }

      const filename = candidateName ? `${candidateName}_Resume_Analysis_Report.pdf` : `report-${index}.pdf`;
      pdf.save(filename);
    }).finally(() => {
      if (button) {
        button.style.display = 'block';
      }
    });
  };

  return (
    <div className="App" style={{ padding: '20px' }}>
      <h1 style={{ textAlign: 'center' }}>Resume vs JD Matcher</h1>
      <form onSubmit={handleSubmit} className="form" style={{ marginBottom: '20px' }}>
        <div className="form-group" style={{ marginBottom: '20px' }}>
          <label>Job Description:</label>
          <textarea
            value={jobDescription}
            onChange={(e) => setJobDescription(e.target.value)}
            placeholder="Enter job description here"
            style={{
              width: '100%',
              padding: '10px',
              borderRadius: '5px',
              border: '1px solid #ced4da',
            }}
          />
        </div>
        <div className="form-group" style={{ marginBottom: '20px' }}>
          <label>Upload Resumes:</label>
          <input
            type="file"
            multiple
            onChange={handleFileUpload}
            style={{
              padding: '10px',
              borderRadius: '5px',
              border: '1px solid #ced4da',
            }}
          />
        </div>
        <button
          type="submit"
          style={{
            backgroundColor: '#007bff',
            color: 'white',
            padding: '10px 20px',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
          }}
        >
          {loading ? 'Analyzing...' : 'Submit'}
        </button>
      </form>

      {loading && <p className="loading">Loading... Please wait.</p>} {/* Spinner here */}

      {reports.length > 0 && (
        <div className="reports">
          {reports.map((report, index) => (
            <div
              key={index}
              ref={(el) => (reportRefs.current[index] = el)}
              style={{
                backgroundColor: '#f8f9fa',
                padding: '20px',
                margin: '20px',
                border: '1px solid #dee2e6',
                borderRadius: '10px',
              }}
            >
              <div style={{ fontSize: '24px', fontWeight: 'bold', color: '#343a40', marginBottom: '10px' }}>
                {report.candidate_name}'s Resume Analysis Report
              </div>
              <div style={{ fontSize: '16px', color: '#495057', lineHeight: '1.6' }}>
                {formatReportWithHeadings(report.report)}
              </div>
              <button
                className="pdf-button"
                onClick={() => downloadPDF(index, report.candidate_name)}
                style={{
                  backgroundColor: '#007bff',
                  color: 'white',
                  padding: '10px 20px',
                  border: 'none',
                  borderRadius: '5px',
                  cursor: 'pointer',
                  marginTop: '20px',
                }}
              >
                Download as PDF
              </button>
            </div>
          ))}
        </div>
      )}

      {errorMessage && <div className="error-message" style={{ color: 'red', marginTop: '20px' }}>{errorMessage}</div>}
    </div>
  );
}

export default JobLens;
