export const subjectData = {
    Algebra1: {
        name: "Algebra 1",
        introText: "Algebra 1 introduces the basics of algebraic thinking, from solving equations and inequalities to understanding functions and graphing linear equations.",
        chapterTopics: {
            "Foundations of Algebra": [
                "Variables and Expressions",
                "Order of Operations",
                "Real Numbers and Their Properties",
                "The Distributive Property",
                "Simplifying Expressions",
                "Introduction to Equations"
            ],
            "Solving Equations": [
                "One-Step Equations",
                "Two-Step Equations",
                "Multi-Step Equations",
                "Equations with Variables on Both Sides",
                "Literal Equations and Formulas",
                "Ratios, Proportions, and Percent Problems"
            ],
            "Solving Inequalities": [
                "Solving One-Step Inequalities",
                "Solving Multi-Step Inequalities",
                "Compound Inequalities",
                "Absolute Value Equations and Inequalities"
            ],
            "Linear Functions": [
                "Graphing Linear Equations",
                "Slope and Rate of Change",
                "Graphing Using Slope-Intercept Form",
                "Graphing Linear Inequalities",
                "Writing Equations of Lines",
                "Direct Variation",
                "Parallel and Perpendicular Lines"
            ],
            "Systems of Equations and Inequalities": [
                "Solving Systems by Graphing",
                "Solving Systems by Substitution",
                "Solving Systems by Elimination",
                "Applications of Systems of Equations",
                "Systems of Inequalities"
            ],
            "Polynomials and Factoring": [
                "Adding and Subtracting Polynomials",
                "Multiplying Polynomials",
                "Special Products of Polynomials",
                "Factoring Trinomials",
                "Factoring Special Cases",
                "Solving Quadratic Equations by Factoring"
            ],
            "Quadratic Functions and Equations": [
                "Graphing Quadratic Functions",
                "Solving Quadratic Equations by Graphing",
                "Solving Quadratic Equations by Taking Square Roots",
                "Completing the Square",
                "The Quadratic Formula",
                "The Discriminant"
            ],
            "Exponential Functions and Sequences": [
                "Exponential Growth and Decay",
                "Graphing Exponential Functions",
                "Geometric Sequences",
                "Arithmetic and Geometric Series"
            ],
            "Radicals and Connections to Geometry": [
                "Simplifying Radicals",
                "Operations with Radical Expressions",
                "Pythagorean Theorem and Its Applications",
                "Solving Radical Equations"
            ],
            "Rational Expressions and Functions": [
                "Simplifying Rational Expressions",
                "Multiplying and Dividing Rational Expressions",
                "Adding and Subtracting Rational Expressions",
                "Solving Rational Equations"
            ]
        }
    },
    PreAlgebra: {
        name: "Pre-Algebra",
        introText: "Pre-Algebra provides a foundation for all future math courses. Learn basic arithmetic, work with integers and fractions, and get introduced to simple equations.",
        chapterTopics: {
            "Number Systems and Operations": [
                "Whole Numbers and Integers",
                "Fractions and Decimals",
                "Mixed Numbers and Improper Fractions",
                "Factors and Multiples",
                "Prime Factorization",
                "Order of Operations (PEMDAS)",
                "Absolute Value"
            ],
            "Ratios, Proportions, and Percents": [
                "Understanding Ratios",
                "Solving Proportions",
                "Percents and Percent Equations",
                "Converting Between Fractions, Decimals, and Percents",
                "Percent Increase and Decrease",
                "Simple Interest"
            ],
            "Algebraic Expressions and Equations": [
                "Variables and Expressions",
                "Simplifying Expressions",
                "Solving One-Step Equations",
                "Solving Two-Step Equations",
                "Distributive Property",
                "Combining Like Terms"
            ],
            "Geometry Basics": [
                "Points, Lines, and Planes",
                "Angles and Their Types",
                "Perimeter and Area of Polygons",
                "Circumference and Area of Circles",
                "Surface Area and Volume of 3D Shapes",
                "Pythagorean Theorem Introduction"
            ],
            "Integers and Rational Numbers": [
                "Adding and Subtracting Integers",
                "Multiplying and Dividing Integers",
                "Understanding Rational Numbers",
                "Adding and Subtracting Rational Numbers",
                "Multiplying and Dividing Rational Numbers"
            ],
            "Data and Statistics": [
                "Interpreting Graphs (Bar, Line, Circle)",
                "Mean, Median, Mode, and Range",
                "Box-and-Whisker Plots",
                "Scatter Plots and Line of Best Fit",
                "Probability Basics"
            ],
            "Introduction to Functions": [
                "Understanding Functions and Relations",
                "Identifying Functions",
                "Graphing Functions",
                "Linear Equations in One Variable",
                "Patterns and Sequences"
            ],
            "Inequalities and Absolute Value": [
                "Writing and Solving Inequalities",
                "Graphing Inequalities",
                "Understanding Absolute Value Equations",
                "Solving Absolute Value Equations"
            ]
        }
    },
    Geometry: {
        name: "Geometry",
        introText: "Explore the spatial reasoning and geometric proofs with topics such as angles, triangles, circles, and more in Geometry.",
        chapterTopics: {
            "Introduction to Geometry": [
                "Points, Lines, and Planes",
                "Line Segments and Angles",
                "Measuring Angles",
                "Classifying Angles",
                "Angle Relationships and Their Properties",
                "Basic Geometric Constructions"
            ],
            "Parallel and Perpendicular Lines": [
                "Parallel Lines and Transversals",
                "Proving Lines Parallel",
                "Perpendicular Lines",
                "Slopes of Lines",
                "Equations of Parallel and Perpendicular Lines",
                "Applications of Parallel and Perpendicular Lines"
            ],
            "Triangles and Congruence": [
                "Classifying Triangles",
                "Triangle Angle-Sum Theorem",
                "Congruent Figures",
                "Proving Triangle Congruence: SSS, SAS, ASA, AAS",
                "Isosceles and Equilateral Triangles",
                "The Midsegment Theorem"
            ],
            "Properties and Attributes of Triangles": [
                "Medians, Altitudes, and Perpendicular Bisectors",
                "The Centroid, Orthocenter, and Circumcenter",
                "The Incenter and its Properties",
                "Inequalities in Triangles",
                "The Triangle Inequality Theorem",
                "The Exterior Angle Theorem"
            ],
            "Quadrilaterals and Polygons": [
                "Classifying Quadrilaterals",
                "Properties of Parallelograms",
                "Proving a Quadrilateral is a Parallelogram",
                "Properties of Special Parallelograms: Rhombuses, Rectangles, and Squares",
                "Properties of Trapezoids and Kites",
                "The Polygon Angle-Sum Theorem"
            ],
            "Similarity": [
                "Ratios and Proportions",
                "Similarity in Polygons",
                "Proving Triangles Similar: AA, SSS, SAS",
                "Similarity in Right Triangles",
                "Proportional Parts of Triangles",
                "Applications of Similar Triangles"
            ],
            "Right Triangles and Trigonometry": [
                "The Pythagorean Theorem and Its Converse",
                "Special Right Triangles: 45-45-90 and 30-60-90",
                "Trigonometric Ratios: Sine, Cosine, Tangent",
                "Solving Right Triangles",
                "Angles of Elevation and Depression",
                "Applications of Trigonometry"
            ],
            "Circles": [
                "Parts of a Circle",
                "Arcs and Central Angles",
                "Inscribed Angles and Polygons",
                "Circumference and Arc Length",
                "Area of Circles and Sectors",
                "Equations of Circles"
            ],
            "Area of Polygons and Circles": [
                "Areas of Triangles and Quadrilaterals",
                "Areas of Regular Polygons",
                "Areas of Circles and Sectors",
                "Areas of Composite Figures",
                "Applications of Area"
            ],
            "Surface Area and Volume": [
                "Surface Area of Prisms and Cylinders",
                "Surface Area of Pyramids and Cones",
                "Surface Area of Spheres",
                "Volume of Prisms and Cylinders",
                "Volume of Pyramids, Cones, and Spheres",
                "Applications of Surface Area and Volume"
            ],
            "Transformations and Symmetry": [
                "Translations, Reflections, and Rotations",
                "Compositions of Transformations",
                "Symmetry in Geometric Figures",
                "Dilations and Scale Factor",
                "Congruence and Similarity in Transformations",
                "Applications of Transformations"
            ],
            "Coordinate Geometry": [
                "Distance and Midpoint Formulas",
                "Slope and Equation of a Line",
                "Proving Geometric Theorems Using Coordinates",
                "Coordinate Proofs Involving Polygons",
                "Equations of Circles and Parabolas",
                "Applications of Coordinate Geometry"
            ],
            "Geometric Probability": [
                "Probability and Area",
                "Using Segments and Areas to Calculate Probability",
                "Expected Value in Geometric Contexts",
                "Geometric Applications of Probability"
            ]
        }
    },
    Algebra2: {
        name: "Algebra 2",
        introText: "Algebra 2 builds on the fundamentals of Algebra 1, introducing complex numbers, advanced functions, and logarithms.",
        chapterTopics: {
            "Functions and Graphs": [
                "Review of Functions",
                "Graphs of Basic Functions",
                "Transformations of Functions",
                "Combinations and Composition of Functions",
                "Inverse Functions",
                "Graphical Analysis and Modeling"
            ],
            "Polynomial and Rational Functions": [
                "Polynomial Functions and Their Graphs",
                "Long Division and Synthetic Division",
                "Zeros of Polynomial Functions",
                "Graphs of Rational Functions",
                "Polynomial and Rational Inequalities",
                "Partial Fractions"
            ],
            "Exponential and Logarithmic Functions": [
                "Exponential Functions",
                "Logarithmic Functions",
                "Properties of Logarithms",
                "Exponential and Logarithmic Equations",
                "Exponential Growth and Decay",
                "Logarithmic Scales"
            ],
            "Trigonometric Functions": [
                "Angles and Their Measure",
                "Unit Circle Approach to Trigonometry",
                "Graphs of Sine and Cosine Functions",
                "Graphs of Other Trigonometric Functions",
                "Inverse Trigonometric Functions",
                "Applications of Trigonometric Functions"
            ],
            "Analytic Trigonometry": [
                "Verifying Trigonometric Identities",
                "Sum and Difference Formulas",
                "Double-Angle, Half-Angle, and Product-to-Sum Formulas",
                "Trigonometric Equations",
                "Inverse Trigonometric Functions"
            ],
            "Additional Topics in Trigonometry": [
                "Law of Sines",
                "Law of Cosines",
                "Polar Coordinates and Polar Graphs",
                "Complex Numbers in Polar Form",
                "De Moivre's Theorem",
                "Vectors and Vector Applications"
            ],
            "Systems of Equations and Inequalities": [
                "Linear Systems in Two and Three Variables",
                "Nonlinear Systems of Equations",
                "Systems of Inequalities",
                "Linear Programming"
            ],
            "Matrices and Determinants": [
                "Matrix Operations",
                "Determinants and Cramer's Rule",
                "Inverse Matrices",
                "Applications of Matrices"
            ],
            "Sequences, Series, and Probability": [
                "Sequences and Series",
                "Arithmetic Sequences and Series",
                "Geometric Sequences and Series",
                "Mathematical Induction",
                "Binomial Theorem",
                "Introduction to Probability"
            ],
            "Conics and Parametric Equations": [
                "Parabolas, Ellipses, and Hyperbolas",
                "Rotation of Axes",
                "Parametric Equations",
                "Applications of Parametric Equations",
                "Polar Equations of Conics"
            ],
            "Limits and an Introduction to Calculus": [
                "Limits and Continuity",
                "The Squeeze Theorem",
                "Introduction to Derivatives",
                "Tangents, Slopes, and Rates of Change",
                "Basic Applications of Derivatives"
            ]
        }
    },
    Calculus: {
        name: "Calculus",
        introText: "Explore limits, derivatives, integrals, and their applications in solving real-world problems in Calculus.",
        chapterTopics: {
            "Limits and Continuity": [
                "Introduction to Limits",
                "Estimating Limits Graphically and Numerically",
                "Properties of Limits",
                "Evaluating Limits Algebraically",
                "One-Sided Limits",
                "Limits Involving Infinity",
                "Continuity and the Intermediate Value Theorem"
            ],
            "Derivatives": [
                "Definition of the Derivative",
                "Derivative as a Function",
                "Basic Differentiation Rules",
                "Product and Quotient Rules",
                "The Chain Rule",
                "Implicit Differentiation",
                "Derivatives of Trigonometric Functions",
                "Derivatives of Exponential and Logarithmic Functions"
            ],
            "Applications of Derivatives": [
                "Related Rates",
                "Extrema on an Interval",
                "Mean Value Theorem",
                "First and Second Derivative Tests",
                "Concavity and Points of Inflection",
                "Optimization Problems",
                "L'Hopital's Rule and Indeterminate Forms",
                "Linear Approximations and Differentials"
            ],
            "Integrals": [
                "Antiderivatives and Indefinite Integration",
                "Basic Integration Rules",
                "Definite Integrals and the Fundamental Theorem of Calculus",
                "Integration by Substitution",
                "Numerical Integration (Trapezoidal and Simpson's Rules)",
                "Improper Integrals"
            ],
            "Applications of Integrals": [
                "Area Under a Curve",
                "Accumulation Functions",
                "Volumes of Solids of Revolution (Disc and Washer Methods)",
                "Arc Length and Surface Area of Solids",
                "Work and Fluid Forces",
                "Average Value of a Function"
            ],
            "Techniques of Integration": [
                "Integration by Parts",
                "Trigonometric Integrals",
                "Trigonometric Substitution",
                "Partial Fraction Decomposition",
                "Integration with Rational Functions",
                "Integration with Roots and Powers"
            ],
            "Differential Equations": [
                "Slope Fields",
                "Euler's Method",
                "Separation of Variables",
                "Exponential Growth and Decay",
                "Logistic Growth Models",
                "Solving Differential Equations"
            ],
            "Infinite Sequences and Series": [
                "Sequences and Their Limits",
                "Series and Convergence",
                "The Integral and Comparison Tests",
                "Alternating Series Test",
                "Ratio and Root Tests",
                "Power Series",
                "Taylor and Maclaurin Series",
                "Applications of Taylor Polynomials"
            ],
            "Parametric Equations, Polar Coordinates, and Vector Functions": [
                "Parametric Equations and Curves",
                "Calculus with Parametric Equations",
                "Polar Coordinates and Graphs",
                "Area and Length in Polar Coordinates",
                "Vectors and Vector-Valued Functions",
                "Calculus of Vector-Valued Functions"
            ]
        }
    },
    DiscreteMath: {
        name: "Discrete Mathematics",
        introText: "Learn about set theory, logic, graph theory, and algorithms. Perfect for those interested in computer science.",
        chapterTopics: {
            "Foundations of Discrete Mathematics": [
                "Propositional Logic and Truth Tables",
                "Logical Equivalences and Quantifiers",
                "Predicate Logic and Proof Techniques",
                "Set Theory and Venn Diagrams"
            ],
            "Relations and Functions": [
                "Relations: Reflexivity, Symmetry, and Transitivity",
                "Equivalence Relations and Partitions",
                "Functions: Injections, Surjections, and Bijections",
                "Function Composition and Inverses"
            ],
            "Algorithms and Complexity": [
                "Introduction to Algorithms and Pseudocode",
                "Big O, Big Omega, and Big Theta Notations",
                "Algorithmic Complexity and Efficiency",
                "Greedy Algorithms and Divide and Conquer"
            ],
            "Counting Principles and Combinatorics": [
                "The Basic Counting Principle",
                "Permutations and Combinations",
                "The Binomial Theorem",
                "Pigeonhole Principle"
            ],
            "Graph Theory": [
                "Introduction to Graphs and Terminology",
                "Eulerian and Hamiltonian Graphs",
                "Graph Coloring and Planarity",
                "Shortest Paths and Spanning Trees"
            ],
            "Recurrence Relations and Generating Functions": [
                "Solving Linear Recurrence Relations",
                "Homogeneous and Non-Homogeneous Relations",
                "Generating Functions and Their Applications"
            ],
            "Number Theory and Cryptography": [
                "Divisibility, Prime Numbers, and Modular Arithmetic",
                "Greatest Common Divisor and Euclidean Algorithm",
                "Introduction to Cryptography and RSA Encryption",
                "Public Key Cryptography"
            ]
        }
    },
    LinearAlgebra: {
        name: "Linear Algebra",
        introText: "Understand vector spaces, matrices, and linear transformations in this critical subject used in many advanced fields.",
        chapterTopics: {
            "Introduction to Linear Algebra": [
                "Scalars, Vectors, and Matrices",
                "Matrix Operations and Properties",
                "Matrix Multiplication and Inverses",
                "Elementary Row Operations",
                "Determinants and Their Properties"
            ],
            "Vector Spaces": [
                "Definition of Vector Spaces and Subspaces",
                "Linear Independence, Basis, and Dimension",
                "Row Space, Column Space, and Null Space",
                "Rank and Nullity Theorem"
            ],
            "Linear Transformations": [
                "Definition of Linear Transformations",
                "Matrix Representation of Linear Transformations",
                "Kernel and Image of a Transformation",
                "Composition of Transformations and Inverses"
            ],
            "Eigenvalues and Eigenvectors": [
                "Definition of Eigenvalues and Eigenvectors",
                "Diagonalization of Matrices",
                "Cayley-Hamilton Theorem",
                "Applications of Eigenvalues in Systems of Differential Equations"
            ],
            "Inner Product Spaces": [
                "Dot Product and Inner Product",
                "Orthogonality and Projections",
                "Gram-Schmidt Process",
                "QR Decomposition"
            ],
            "Advanced Topics in Linear Algebra": [
                "Singular Value Decomposition (SVD)",
                "Jordan Canonical Form",
                "Matrix Factorizations (LU, Cholesky, etc.)",
                "Applications of Linear Algebra in Computer Graphics, Machine Learning, etc."
            ]
        }
    },
    NumberTheory: {
        name: "Number Theory",
        introText: "Explore the world of integers, prime numbers, modular arithmetic, and cryptography. Ideal for those fascinated by the foundational aspects of mathematics.",
        chapterTopics: {
            "Introduction to Number Theory": [
                "Divisibility and the Division Algorithm",
                "Greatest Common Divisor (GCD) and Least Common Multiple (LCM)",
                "Prime Numbers and Their Properties",
                "Fundamental Theorem of Arithmetic"
            ],
            "Modular Arithmetic": [
                "Congruences and Modular Equivalence",
                "Solving Linear Congruences",
                "Chinese Remainder Theorem",
                "Fermat’s Little Theorem"
            ],
            "Diophantine Equations": [
                "Linear Diophantine Equations",
                "Pell’s Equation",
                "Fermat’s Last Theorem (Overview)",
                "Applications in Cryptography"
            ],
            "Quadratic Residues and Reciprocity": [
                "Legendre Symbols and Euler's Criterion",
                "Quadratic Reciprocity Theorem",
                "Jacobi Symbol",
                "Applications to Cryptography"
            ],
            "Advanced Topics in Number Theory": [
                "Mersenne Primes and Perfect Numbers",
                "Elliptic Curves and Applications in Cryptography",
                "Distribution of Prime Numbers",
                "Introduction to Algebraic Number Theory"
            ],
            "Applications of Number Theory": [
                "RSA Encryption and Public-Key Cryptography",
                "Diffie-Hellman Key Exchange",
                "Elliptic Curve Cryptography",
                "Applications in Coding Theory"
            ]
        }
    },
    Precalculus: {
        name: "Precalculus",
        introText: "Precalculus builds on algebra and trigonometry, preparing students for the rigors of calculus with topics like limits and advanced functions.",
        chapterTopics: {
            "Functions and Graphs": [
                "Review of Functions",
                "Graphs of Basic Functions",
                "Transformations of Functions",
                "Combinations and Composition of Functions",
                "Inverse Functions",
                "Graphical Analysis and Modeling"
            ],
            "Polynomial and Rational Functions": [
                "Polynomial Functions and Their Graphs",
                "Long Division and Synthetic Division",
                "Zeros of Polynomial Functions",
                "Graphs of Rational Functions",
                "Polynomial and Rational Inequalities",
                "Partial Fractions"
            ],
            "Exponential and Logarithmic Functions": [
                "Exponential Functions",
                "Logarithmic Functions",
                "Properties of Logarithms",
                "Exponential and Logarithmic Equations",
                "Exponential Growth and Decay",
                "Logarithmic Scales"
            ],
            "Trigonometric Functions": [
                "Angles and Their Measure",
                "Unit Circle Approach to Trigonometry",
                "Graphs of Sine and Cosine Functions",
                "Graphs of Other Trigonometric Functions",
                "Inverse Trigonometric Functions",
                "Applications of Trigonometric Functions"
            ],
            "Analytic Trigonometry": [
                "Verifying Trigonometric Identities",
                "Sum and Difference Formulas",
                "Double-Angle, Half-Angle, and Product-to-Sum Formulas",
                "Trigonometric Equations",
                "Inverse Trigonometric Functions"
            ],
            "Additional Topics in Trigonometry": [
                "Law of Sines",
                "Law of Cosines",
                "Polar Coordinates and Polar Graphs",
                "Complex Numbers in Polar Form",
                "De Moivre's Theorem",
                "Vectors and Vector Applications"
            ],
            "Systems of Equations and Inequalities": [
                "Linear Systems in Two and Three Variables",
                "Nonlinear Systems of Equations",
                "Systems of Inequalities",
                "Linear Programming"
            ],
            "Matrices and Determinants": [
                "Matrix Operations",
                "Determinants and Cramer's Rule",
                "Inverse Matrices",
                "Applications of Matrices"
            ],
            "Sequences, Series, and Probability": [
                "Sequences and Series",
                "Arithmetic Sequences and Series",
                "Geometric Sequences and Series",
                "Mathematical Induction",
                "Binomial Theorem",
                "Introduction to Probability"
            ],
            "Conics and Parametric Equations": [
                "Parabolas, Ellipses, and Hyperbolas",
                "Rotation of Axes",
                "Parametric Equations",
                "Applications of Parametric Equations",
                "Polar Equations of Conics"
            ],
            "Limits and an Introduction to Calculus": [
                "Limits and Continuity",
                "The Squeeze Theorem",
                "Introduction to Derivatives",
                "Tangents, Slopes, and Rates of Change",
                "Basic Applications of Derivatives"
            ]
        }
    },
    Statistics: {
        name: "Statistics",
        introText: "Dive into the world of data analysis and chance. Learn how to organize, analyze, and interpret data, as well as calculate probabilities.",
        chapterTopics: {
            "Introduction to Statistics": [
                "What is Statistics?",
                "Types of Data",
                "Sampling Methods",
                "Bias in Sampling",
                "Descriptive vs Inferential Statistics"
            ],
            "Organizing and Displaying Data": [
                "Frequency Distributions",
                "Histograms",
                "Stem-and-Leaf Plots",
                "Bar Graphs and Pie Charts",
                "Box-and-Whisker Plots",
                "Scatterplots"
            ],
            "Descriptive Statistics": [
                "Measures of Central Tendency: Mean, Median, Mode",
                "Measures of Variation: Range, Variance, Standard Deviation",
                "Z-Scores and Percentiles",
                "The Empirical Rule and Chebyshev’s Theorem"
            ],
            "Probability": [
                "Basic Probability Concepts",
                "Addition and Multiplication Rules",
                "Conditional Probability and Independence",
                "Permutations and Combinations",
                "Bayes' Theorem"
            ],
            "Discrete Probability Distributions": [
                "Random Variables",
                "Discrete Probability Distributions",
                "Binomial Distributions",
                "Geometric and Poisson Distributions"
            ],
            "Normal Probability Distributions": [
                "The Standard Normal Distribution",
                "Finding Probabilities using the Standard Normal Table",
                "Applications of the Normal Distribution",
                "Sampling Distributions and the Central Limit Theorem"
            ],
            "Estimation and Confidence Intervals": [
                "Point and Interval Estimates",
                "Confidence Intervals for Means and Proportions",
                "Determining Sample Size",
                "Margin of Error"
            ],
            "Hypothesis Testing": [
                "Steps in Hypothesis Testing",
                "Type I and Type II Errors",
                "Hypothesis Tests for Means",
                "Hypothesis Tests for Proportions",
                "P-Values and Significance Levels",
                "Chi-Square Tests for Independence"
            ],
            "Correlation and Regression": [
                "Scatterplots and Correlation",
                "The Correlation Coefficient",
                "Simple Linear Regression",
                "Interpreting the Slope and Intercept",
                "Using Regression for Prediction",
                "Coefficient of Determination"
            ],
            "Analysis of Variance (ANOVA)": [
                "F-Distribution and ANOVA",
                "One-Way ANOVA",
                "Testing Differences Between Means",
                "Post Hoc Comparisons"
            ],
            "Non-Parametric Tests": [
                "The Sign Test",
                "The Mann-Whitney U Test",
                "The Kruskal-Wallis Test",
                "Wilcoxon Signed-Rank Test"
            ]
        }
    },
    Biology: {
        name: "Biology",
        introText: "Biology explores the living world, from the smallest cells to the vast ecosystems on Earth, covering essential topics in genetics, evolution, and physiology.",
        chapterTopics: {
            "Introduction to Biology": [
                "What is Biology?",
                "Branches of Biology",
                "The Scientific Method and Experimental Design",
                "Characteristics of Living Organisms",
                "Levels of Biological Organization",
                "Biology and Society"
            ],
            "The Chemistry of Life": [
                "Atoms and Molecules",
                "Chemical Bonds",
                "Water and Life",
                "The Properties of Water",
                "Macromolecules: Carbohydrates, Lipids, Proteins, Nucleic Acids",
                "Enzymes and Metabolism"
            ],
            "Cell Structure and Function": [
                "Cell Theory",
                "Prokaryotic vs. Eukaryotic Cells",
                "Organelles and Their Functions",
                "Cell Membrane Structure and Function",
                "Transport Across the Cell Membrane (Passive and Active Transport)",
                "Endocytosis and Exocytosis"
            ],
            "Cell Division and Reproduction": [
                "The Cell Cycle",
                "Mitosis and Cytokinesis",
                "Meiosis and Gametogenesis",
                "Asexual vs. Sexual Reproduction",
                "Regulation of the Cell Cycle",
                "Cancer and Uncontrolled Cell Growth"
            ],
            "Genetics": [
                "DNA Structure and Replication",
                "RNA and Protein Synthesis (Transcription and Translation)",
                "Genetic Code and Mutations",
                "Mendelian Genetics and Heredity",
                "Patterns of Inheritance (Codominance, Incomplete Dominance, Multiple Alleles)",
                "Punnett Squares and Pedigree Analysis",
                "Chromosomal Inheritance and Disorders",
                "Gene Expression and Regulation"
            ],
            "Biotechnology and Genetic Engineering": [
                "Recombinant DNA Technology",
                "CRISPR and Genome Editing",
                "Cloning and Stem Cells",
                "DNA Fingerprinting and Forensics",
                "Genetically Modified Organisms (GMOs)",
                "Applications of Biotechnology in Medicine and Agriculture",
                "Ethics in Genetic Engineering"
            ],
            "Evolution and Natural Selection": [
                "History of Evolutionary Thought",
                "Darwin's Theory of Natural Selection",
                "Mechanisms of Evolution (Mutation, Gene Flow, Genetic Drift)",
                "Speciation and Extinction",
                "Evidence for Evolution (Fossils, Anatomy, Molecular Biology)",
                "Population Genetics (Hardy-Weinberg Equilibrium)",
                "Adaptive Radiation and Convergent Evolution",
                "Human Evolution and Ancestry"
            ],
            "Ecology and Ecosystems": [
                "Levels of Ecological Study (Organisms, Populations, Communities, Ecosystems, Biosphere)",
                "Energy Flow in Ecosystems (Food Chains, Food Webs, Trophic Levels)",
                "Nutrient Cycles (Carbon, Nitrogen, Water)",
                "Biomes and Ecosystems (Tropical Rainforest, Desert, Tundra, etc.)",
                "Population Ecology (Carrying Capacity, Growth Models, Limiting Factors)",
                "Ecological Succession",
                "Human Impact on Ecosystems (Deforestation, Pollution, Climate Change)",
                "Conservation Biology and Biodiversity"
            ],
            "Plant Biology": [
                "Structure of Plants (Roots, Stems, Leaves, Flowers)",
                "Plant Cells and Tissues",
                "Photosynthesis and Cellular Respiration in Plants",
                "Plant Hormones and Growth",
                "Reproduction in Plants (Angiosperms and Gymnosperms)",
                "Pollination and Fertilization",
                "Seed Development and Germination",
                "Plant Evolution and Classification"
            ],
            "Animal Anatomy and Physiology": [
                "The Human Body and Its Systems",
                "Skeletal and Muscular Systems",
                "Circulatory System (Heart, Blood, Vessels)",
                "Respiratory System (Lungs, Gas Exchange)",
                "Digestive System (Organs, Enzymes, Nutrient Absorption)",
                "Excretory System (Kidneys, Urinary System)",
                "Nervous System (Brain, Spinal Cord, Neurons)",
                "Endocrine System (Hormones and Glands)",
                "Reproductive System (Male and Female Anatomy, Gametogenesis)",
                "Immune System (Defense Mechanisms, White Blood Cells, Antibodies)"
            ],
            "Microorganisms and Infectious Diseases": [
                "Bacteria (Structure, Reproduction, Roles in the Ecosystem)",
                "Viruses (Structure, Reproduction, Diseases)",
                "Fungi and Protists",
                "The Immune System Response",
                "Vaccination and Immunity",
                "Antibiotic Resistance",
                "Epidemiology and Public Health",
                "Notable Infectious Diseases (HIV/AIDS, Tuberculosis, COVID-19)"
            ],
            "Behavior and Adaptation": [
                "Animal Behavior (Innate and Learned Behavior)",
                "Social Behavior in Animals",
                "Communication and Mating Systems",
                "Survival Strategies (Migration, Hibernation)",
                "Human Behavior and Evolution",
                "Adaptive Behavior in Different Environments"
            ],
            "Human Impact on the Environment": [
                "Deforestation and Habitat Destruction",
                "Pollution (Air, Water, Soil)",
                "Global Warming and Climate Change",
                "Conservation Efforts and Sustainability",
                "Endangered Species and Extinction",
                "Renewable Energy and Environmental Policy",
                "Role of Science in Addressing Environmental Issues"
            ],
        }
    },
    Chemistry: {
        name: "Chemistry",
        introText: "Dive into the world of matter, its composition, properties, and the reactions that bring life to the universe in Chemistry.",
        chapterTopics: {
            "Introduction to Chemistry": [
                "Scientific Method",
                "Units of Measurement",
                "States of Matter",
                "Physical and Chemical Properties",
                "Elements, Compounds, and Mixtures",
                "Law of Conservation of Mass"
            ],
            "Atomic Structure": [
                "Atoms and Subatomic Particles",
                "Atomic Number and Mass Number",
                "Isotopes",
                "Electron Configurations",
                "Periodic Table Trends",
                "Ionization Energy and Electronegativity"
            ],
            "Chemical Bonds": [
                "Ionic Bonds",
                "Covalent Bonds",
                "Metallic Bonds",
                "Lewis Structures",
                "Polarity",
                "Intermolecular Forces"
            ],
            "Chemical Reactions": [
                "Types of Chemical Reactions",
                "Balancing Chemical Equations",
                "Reaction Stoichiometry",
                "Limiting Reactants and Percent Yield",
                "Energy Changes in Reactions",
                "Catalysts and Reaction Rates"
            ],
            "States of Matter and Thermodynamics": [
                "Gas Laws (Boyle, Charles, Avogadro)",
                "Kinetic Molecular Theory",
                "Phase Changes",
                "Heat and Temperature",
                "Laws of Thermodynamics",
                "Enthalpy and Entropy"
            ],
            "Solutions": [
                "Types of Solutions",
                "Solubility and Factors Affecting Solubility",
                "Concentration Units (Molarity, Molality)",
                "Colligative Properties",
                "Acids and Bases",
                "pH Scale and pOH"
            ],
            "Equilibrium and Kinetics": [
                "Dynamic Equilibrium",
                "Le Chatelier’s Principle",
                "Reaction Rates",
                "Rate Laws",
                "Activation Energy",
                "Factors Affecting Reaction Rates"
            ],
            "Acids, Bases, and Salts": [
                "Arrhenius, Bronsted-Lowry, and Lewis Acids and Bases",
                "Strong and Weak Acids and Bases",
                "Acid-Base Titration",
                "Buffers",
                "Salts and Hydrolysis",
                "pH Calculations"
            ],
            "Electrochemistry": [
                "Redox Reactions",
                "Oxidation States",
                "Electrochemical Cells",
                "Standard Reduction Potentials",
                "Batteries and Fuel Cells",
                "Electrolysis"
            ],
            "Organic Chemistry": [
                "Introduction to Organic Chemistry",
                "Hydrocarbons",
                "Functional Groups",
                "Isomerism",
                "Polymers and Polymerization",
                "Reactions of Organic Compounds"
            ]
        }
    },
    Physics: {
        name: "Physics",
        introText: "Explore the fundamental laws that govern the universe, from motion and energy to electricity, magnetism, and modern physics.",
        chapterTopics: {
            "Introduction to Physics": [
                "What is Physics?",
                "Branches of Physics",
                "The Scientific Method",
                "Units and Measurements in Physics",
                "Dimensional Analysis"
            ],
            "Kinematics": [
                "Scalars and Vectors",
                "Distance, Displacement, Speed, and Velocity",
                "Acceleration",
                "Motion in One Dimension",
                "Motion in Two Dimensions",
                "Projectile Motion"
            ],
            "Dynamics": [
                "Newton’s Laws of Motion",
                "Inertia, Force, and Mass",
                "Friction",
                "Free-Body Diagrams",
                "Circular Motion and Centripetal Force",
                "Gravitational Force"
            ],
            "Work, Energy, and Power": [
                "Work and Mechanical Energy",
                "Kinetic and Potential Energy",
                "Conservation of Energy",
                "Power",
                "Efficiency of Machines"
            ],
            "Momentum and Collisions": [
                "Linear Momentum",
                "Impulse",
                "Conservation of Momentum",
                "Elastic and Inelastic Collisions"
            ],
            "Rotational Motion": [
                "Angular Velocity and Acceleration",
                "Torque",
                "Moment of Inertia",
                "Rotational Kinetic Energy",
                "Conservation of Angular Momentum"
            ],
            "Waves and Oscillations": [
                "Types of Waves (Transverse and Longitudinal)",
                "Wave Properties (Frequency, Wavelength, Amplitude)",
                "Wave Speed",
                "Standing Waves",
                "Simple Harmonic Motion",
                "Resonance"
            ],
            "Sound and Light": [
                "Properties of Sound Waves",
                "The Doppler Effect",
                "The Electromagnetic Spectrum",
                "Reflection and Refraction",
                "Diffraction and Interference",
                "Lenses and Mirrors"
            ],
            "Electricity and Magnetism": [
                "Electric Charge and Electric Force",
                "Coulomb’s Law",
                "Electric Fields and Potential",
                "Capacitors and Dielectrics",
                "Magnetic Fields and Forces",
                "Electromagnetic Induction"
            ],
            "Thermodynamics": [
                "Temperature and Heat",
                "The Laws of Thermodynamics",
                "Heat Transfer (Conduction, Convection, Radiation)",
                "Heat Engines and Efficiency",
                "Entropy"
            ],
            "Modern Physics": [
                "Quantum Mechanics",
                "The Photoelectric Effect",
                "The Dual Nature of Light",
                "Nuclear Physics",
                "Special Relativity",
                "The Standard Model of Particle Physics"
            ]
        }
    },
    EarthScience: {
        name: "Earth Science",
        introText: "Earth Science focuses on understanding Earth’s systems, including geology, meteorology, oceanography, and the interactions that shape our planet.",
        chapterTopics: {
            "Introduction to Earth Science": [
                "What is Earth Science?",
                "Branches of Earth Science",
                "The Scientific Method in Earth Science",
                "Earth's Spheres: Lithosphere, Hydrosphere, Atmosphere, and Biosphere",
                "Earth Science and Society"
            ],
            "Geology": [
                "Introduction to Geology",
                "Minerals: Types and Identification",
                "Rock Types: Igneous, Sedimentary, and Metamorphic",
                "Plate Tectonics and Continental Drift",
                "Earthquakes: Causes and Seismic Waves",
                "Volcanoes: Eruptions and Types",
                "Weathering, Erosion, and Soil Formation",
                "Geologic Time Scale",
                "Fossils and the History of Life",
                "Earth’s Interior: Layers of the Earth"
            ],
            "Meteorology": [
                "Atmospheric Composition and Layers",
                "Weather and Climate",
                "The Water Cycle",
                "Air Pressure, Wind, and Weather Systems",
                "Clouds and Precipitation",
                "Storms: Thunderstorms, Tornadoes, Hurricanes"
            ],
            "Oceanography": [
                "Oceans and Their Properties",
                "Ocean Circulation and Currents",
                "Marine Ecosystems and Food Chains",
                "Ocean Floor and Plate Tectonics",
                "Tides, Waves, and Coastal Erosion"
            ],
            "Astronomy in Earth Science": [
                "Earth's Place in the Universe",
                "The Solar System: Planets and Moons",
                "The Sun-Earth-Moon System",
                "Seasons, Eclipses, and Tides",
                "Stars, Galaxies, and the Expanding Universe"
            ],
            "Geological Time and Fossils": [
                "The Geologic Time Scale",
                "Fossils and Their Formation",
                "Dating Methods: Relative and Absolute",
                "Mass Extinctions and the Fossil Record",
                "Earth's Geological History"
            ],
            "Earth's Resources": [
                "Renewable vs. Nonrenewable Resources",
                "Fossil Fuels and Energy Resources",
                "Alternative Energy Sources",
                "Mineral Resources and Mining",
                "Environmental Conservation and Sustainability"
            ],
            "Natural Hazards and Disasters": [
                "Earthquakes and Tsunamis",
                "Volcanic Eruptions",
                "Landslides and Floods",
                "Hurricanes and Tornadoes",
                "Climate Change and Global Warming"
            ]
        }
    },

    EnvironmentalScience: {
        name: "Environmental Science",
        introText: "Environmental Science focuses on understanding the interactions between humans and the environment, including ecosystems, natural resources, and sustainability.",
        chapterTopics: {
            "Introduction to Environmental Science": [
                "What is Environmental Science?",
                "The Environment and Society",
                "Ecosystems and Energy Flow",
                "Sustainability and Conservation",
                "The Scientific Method in Environmental Science"
            ],
            "Ecosystems and Biomes": [
                "Trophic Levels and Food Chains",
                "Energy Flow in Ecosystems",
                "Biogeochemical Cycles",
                "Terrestrial Biomes: Forests, Grasslands, Deserts",
                "Aquatic Biomes: Freshwater and Marine Ecosystems"
            ],
            "Populations and Communities": [
                "Population Dynamics and Growth",
                "Carrying Capacity and Limiting Factors",
                "Human Population Growth and Impact",
                "Community Interactions: Predation, Competition, Symbiosis",
                "Biodiversity and Conservation"
            ],
            "Natural Resources": [
                "Renewable vs Nonrenewable Resources",
                "Water Resources and Management",
                "Soil Conservation and Agriculture",
                "Forests and Deforestation",
                "Mining and Mineral Resources"
            ],
            "Pollution and Waste": [
                "Air Pollution and Its Effects",
                "Water Pollution and Its Sources",
                "Soil Pollution and Remediation",
                "Waste Management and Recycling",
                "Hazardous Waste and Environmental Health"
            ],
            "Climate and Climate Change": [
                "The Greenhouse Effect and Global Warming",
                "Weather vs Climate",
                "Climate Zones and Global Patterns",
                "Human Activities and Climate Change",
                "Mitigation and Adaptation Strategies"
            ],
            "Energy Resources": [
                "Fossil Fuels and Their Environmental Impact",
                "Nuclear Energy and Radiation",
                "Renewable Energy: Solar, Wind, Geothermal, Biomass",
                "Energy Efficiency and Conservation",
                "Environmental Impacts of Energy Production"
            ],
            "Sustainability and Environmental Policy": [
                "Sustainable Development Goals",
                "Environmental Policy and Regulation",
                "Environmental Ethics",
                "International Environmental Agreements",
                "Local and Global Environmental Movements"
            ],
            "Human Impact on the Environment": [
                "Urbanization and Land Use",
                "Agriculture and the Environment",
                "Industrialization and Pollution",
                "Global Environmental Issues: Deforestation, Desertification, Ocean Acidification",
                "Restoring Ecosystems and Habitats"
            ]
        }
    },
    Astronomy: {
        name: "Astronomy",
        introText: "Astronomy explores the universe beyond our planet, delving into the origins and workings of celestial bodies, galaxies, and the fundamental forces governing space.",
        chapterTopics: {
            "Introduction to Astronomy": [
                "What is Astronomy?",
                "History of Astronomy",
                "Tools and Techniques of Astronomy",
                "Branches of Astronomy: Observational vs Theoretical",
                "Astronomy and Society"
            ],
            "The Solar System": [
                "The Sun and Its Structure",
                "The Planets: Inner and Outer",
                "Moons, Dwarf Planets, and Asteroids",
                "Comets and Meteoroids",
                "The Formation of the Solar System"
            ],
            "Stars and Stellar Evolution": [
                "Types of Stars and Star Formation",
                "Nuclear Fusion and Stellar Lifecycles",
                "Main Sequence Stars",
                "Red Giants, White Dwarfs, and Supernovae",
                "Black Holes, Neutron Stars, and Pulsars"
            ],
            "Galaxies and the Universe": [
                "The Milky Way Galaxy",
                "Types of Galaxies: Spiral, Elliptical, and Irregular",
                "Dark Matter and Dark Energy",
                "The Expanding Universe",
                "Galactic Collisions and Interactions"
            ],
            "Cosmology and the Big Bang": [
                "The Big Bang Theory",
                "Cosmic Microwave Background Radiation",
                "The Age and Fate of the Universe",
                "Inflation and the Early Universe",
                "The Multiverse Theory"
            ],
            "Exoplanets and Life Beyond Earth": [
                "Methods of Exoplanet Detection",
                "Types of Exoplanets: Hot Jupiters, Super-Earths, and More",
                "The Habitable Zone and the Search for Life",
                "Astrobiology: Life on Other Worlds",
                "The Drake Equation and SETI"
            ],
            "Space Exploration": [
                "History of Space Exploration",
                "The Apollo Missions and Moon Landings",
                "The International Space Station (ISS)",
                "Mars Rovers and Robotic Explorers",
                "Future of Human Space Travel: Mars and Beyond"
            ],
            "Telescopes and Observatories": [
                "The Hubble Space Telescope",
                "Ground-Based Observatories",
                "Radio Telescopes and Interferometry",
                "Upcoming Space Telescopes: James Webb and Beyond",
                "Citizen Science and Amateur Astronomy"
            ],
            "Astrophysics and Gravity": [
                "Gravity and Orbits",
                "General Relativity and Black Holes",
                "The Electromagnetic Spectrum",
                "Gravitational Waves",
                "Astrophysical Phenomena: Quasars, Blazars, and Gamma-Ray Bursts"
            ],
            "Astronomy in Popular Culture": [
                "Astronomy in Mythology and Religion",
                "Astronomy in Science Fiction",
                "Influence of Astronomy on Art and Literature",
                "Famous Astronomers: Galileo, Newton, Einstein, and More",
                "The Future of Astronomy"
            ]
        }
    },
    Ecology: {
        name: "Ecology",
        introText: "Ecology studies the relationships between organisms and their environment, focusing on ecosystems, biodiversity, and the impact of human activities on nature.",
        chapterTopics: {
            "Introduction to Ecology": [
                "What is Ecology?",
                "Levels of Ecological Organization",
                "Abiotic and Biotic Factors",
                "Energy Flow in Ecosystems",
                "The Role of Producers, Consumers, and Decomposers"
            ],
            "Population Ecology": [
                "Population Growth and Limiting Factors",
                "Carrying Capacity",
                "Population Density and Dispersion",
                "Human Population Growth and Impact"
            ],
            "Community Ecology": [
                "Species Interactions: Predation, Competition, and Symbiosis",
                "Ecological Niches and Habitat",
                "Keystone Species",
                "Succession: Primary and Secondary"
            ],
            "Ecosystem Ecology": [
                "Nutrient Cycles: Carbon, Nitrogen, and Water Cycles",
                "Ecosystem Services",
                "Trophic Levels and Food Webs",
                "Energy Pyramids and Biomass",
                "Ecosystem Stability and Disturbances"
            ],
            "Conservation Biology": [
                "Biodiversity and Its Importance",
                "Endangered Species and Conservation Efforts",
                "Habitat Destruction and Restoration",
                "Invasive Species and Their Impact",
                "Climate Change and Conservation"
            ],
            "Human Impact on the Environment": [
                "Deforestation and Habitat Loss",
                "Pollution: Air, Water, and Soil",
                "Global Warming and Climate Change",
                "Overfishing and Desertification",
                "Sustainable Practices and Solutions"
            ]
        }
    },
    HumanAnatomy: {
        name: "Anatomy and Physiology",
        introText: "Anatomy and Physiology delve into the structure and function of the human body, exploring everything from cellular processes to organ systems and their roles in maintaining life.",
        chapterTopics: {
            "Introduction to Human Anatomy": [
                "Levels of Structural Organization",
                "Body Systems Overview",
                "Homeostasis and Feedback Mechanisms",
                "Anatomical Terminology",
                "Body Cavities and Membranes"
            ],
            "Cells and Tissues": [
                "Cell Structure and Function",
                "Cell Membrane and Transport",
                "Cell Division: Mitosis and Meiosis",
                "Histology: Types of Tissues",
                "Tissue Repair and Regeneration"
            ],
            "Skeletal System": [
                "Bone Structure and Function",
                "Bone Growth and Remodeling",
                "Axial and Appendicular Skeleton",
                "Joints and Movement",
                "Bone Disorders: Osteoporosis and Arthritis"
            ],
            "Muscular System": [
                "Types of Muscle Tissue",
                "Muscle Structure and Contraction",
                "Skeletal Muscle Physiology",
                "Muscle Fatigue and Exercise",
                "Muscular System Disorders"
            ],
            "Nervous System": [
                "Neurons and Neuroglia",
                "The Central and Peripheral Nervous Systems",
                "Synaptic Transmission and Neurotransmitters",
                "Reflexes and Neural Pathways",
                "Neurological Disorders: Parkinson's, Alzheimer's"
            ],
            "Circulatory System": [
                "Heart Anatomy and Function",
                "Blood Vessels and Circulation",
                "The Cardiac Cycle",
                "Blood Composition and Functions",
                "Disorders: Hypertension, Heart Disease"
            ],
            "Respiratory System": [
                "Anatomy of the Respiratory Tract",
                "Mechanics of Breathing",
                "Gas Exchange in the Lungs",
                "Control of Respiration",
                "Respiratory System Disorders: Asthma, Pneumonia"
            ],
            "Digestive System": [
                "Overview of Digestive Processes",
                "Anatomy of the Digestive Tract",
                "Nutrient Absorption and Enzymes",
                "Metabolism and Energy Balance",
                "Digestive System Disorders: Ulcers, IBS"
            ],
            "Endocrine System": [
                "Hormones and Their Functions",
                "Major Endocrine Glands",
                "Regulation of Metabolism",
                "Endocrine Disorders: Diabetes, Thyroid Issues"
            ],
            "Urinary System": [
                "Kidney Structure and Function",
                "Formation of Urine",
                "Fluid and Electrolyte Balance",
                "Acid-Base Balance",
                "Disorders: Kidney Stones, Infections"
            ],
            "Reproductive System": [
                "Male and Female Reproductive Anatomy",
                "Hormonal Control of Reproduction",
                "Fertilization and Development",
                "Pregnancy and Birth",
                "Reproductive System Disorders"
            ]
        }
    },
    Microbiology: {
        name: "Microbiology",
        introText: "Microbiology explores the microscopic world of organisms, focusing on bacteria, viruses, fungi, and protozoa, and their roles in health, disease, and the environment.",
        chapterTopics: {
            "Introduction to Microbiology": [
                "What is Microbiology?",
                "History and Scope of Microbiology",
                "Microorganisms and Their Types",
                "Prokaryotic vs Eukaryotic Cells",
                "Microscopy and Staining Techniques"
            ],
            "Bacteria and Archaea": [
                "Structure and Function of Bacterial Cells",
                "Bacterial Growth and Reproduction",
                "Bacterial Metabolism and Nutrition",
                "Classification of Bacteria",
                "Archaea and Extremophiles"
            ],
            "Viruses and Prions": [
                "Structure and Classification of Viruses",
                "Viral Replication Cycles",
                "DNA vs RNA Viruses",
                "Prions and Infectious Proteins",
                "Viral Diseases and Prevention"
            ],
            "Fungi and Protists": [
                "Structure and Function of Fungi",
                "Reproduction in Fungi",
                "Fungal Infections and Diseases",
                "Protists: Characteristics and Types",
                "Ecological Importance of Fungi and Protists"
            ],
            "Microbial Genetics": [
                "DNA and RNA in Microorganisms",
                "Gene Expression and Regulation",
                "Mutations and Genetic Variation",
                "Horizontal Gene Transfer: Conjugation, Transformation, Transduction",
                "Applications of Microbial Genetics"
            ],
            "Immunology and Pathogenic Microorganisms": [
                "Introduction to Immunology",
                "Host-Pathogen Interactions",
                "Innate and Adaptive Immunity",
                "Pathogenic Bacteria, Viruses, and Fungi",
                "Vaccination and Disease Prevention"
            ],
            "Microbial Ecology and Environmental Microbiology": [
                "Microorganisms in the Environment",
                "Biogeochemical Cycles and Microbes",
                "Microbes in Soil, Water, and Air",
                "Symbiotic Relationships: Microbes and Humans",
                "Applications of Environmental Microbiology"
            ],
            "Applied Microbiology": [
                "Industrial Microbiology: Fermentation and Bioprocessing",
                "Microbes in Food Production and Spoilage",
                "Medical Microbiology: Antibiotics and Drug Resistance",
                "Biotechnology and Genetic Engineering",
                "Bioremediation and Microbial Technology"
            ]
        }
    },
    Geology: {
        name: "Geology",
        introText: "Geology is the study of Earth's physical structure, the materials it’s composed of, and the processes that shape it over time, including the forces that build up mountains and trigger earthquakes.",
        chapterTopics: {
            "Introduction to Geology": [
                "What is Geology?",
                "Historical Development of Geology",
                "Branches of Geology (Physical, Historical, Environmental)",
                "The Role of Geology in Society",
                "Geology and the Scientific Method"
            ],
            "Earth Materials: Minerals and Rocks": [
                "Introduction to Minerals: Properties and Classification",
                "Rock Cycle: Igneous, Sedimentary, and Metamorphic Rocks",
                "Igneous Rocks: Formation and Types",
                "Sedimentary Rocks: Formation, Types, and Fossils",
                "Metamorphic Rocks: Metamorphism and Types"
            ],
            "Earth’s Interior and Plate Tectonics": [
                "Structure of Earth: Crust, Mantle, Core",
                "Theory of Plate Tectonics: History and Evidence",
                "Continental Drift and Seafloor Spreading",
                "Tectonic Plate Boundaries: Convergent, Divergent, Transform",
                "Earthquakes, Volcanoes, and Plate Tectonics"
            ],
            "Earthquakes and Volcanism": [
                "Causes of Earthquakes and Faults",
                "Seismic Waves and Measuring Earthquakes",
                "Tsunamis and Earthquake Hazards",
                "Volcanic Eruptions: Types of Volcanoes and Lava Flows",
                "Volcano Hazards and Monitoring"
            ],
            "Weathering, Erosion, and Soil Formation": [
                "Weathering: Mechanical vs. Chemical",
                "Erosion and Its Causes: Water, Wind, Ice",
                "Soil Formation and Composition",
                "Types of Soil and Soil Erosion",
                "Karst Landscapes and Sinkholes"
            ],
            "Geologic Time and Fossils": [
                "Understanding Geologic Time: Relative and Absolute Dating",
                "The Geologic Time Scale",
                "Formation of Fossils and Types",
                "The Fossil Record and Evolution",
                "Mass Extinctions in Earth’s History"
            ],
            "Geologic Structures: Folds, Faults, and Mountains": [
                "Stress and Strain in Earth’s Crust",
                "Folds and Faults: Types and Examples",
                "Mountain Building: Orogenesis",
                "Types of Mountains: Volcanic, Fold, Fault-Block",
                "Famous Mountain Ranges and Their Formation"
            ],
            "Sedimentation and Stratigraphy": [
                "Sedimentary Processes and Environments",
                "Sedimentary Basins and Stratification",
                "Principles of Stratigraphy: Superposition, Cross-Cutting",
                "Unconformities and Gaps in the Geologic Record",
                "Correlation of Rock Layers and Fossils"
            ],
            "Glaciation and Ice Ages": [
                "Glaciers: Formation and Types",
                "Glacial Erosion and Deposition",
                "Ice Ages and Their Causes",
                "Glacial Landforms: Moraines, Drumlins, Kettles",
                "Effects of Glaciation on Earth’s Surface"
            ],
            "Hydrology and Groundwater": [
                "The Hydrologic Cycle: Movement of Water",
                "Rivers and Streams: Erosion, Transportation, Deposition",
                "Groundwater: Aquifers, Springs, and Wells",
                "Groundwater Pollution and Management",
                "Floods and Flood Control"
            ],
            "Oceans and Coastal Processes": [
                "Formation of Oceans and Ocean Basins",
                "Ocean Currents, Tides, and Waves",
                "Marine Ecosystems and Food Chains",
                "Oceanic Plate Tectonics and Mid-Ocean Ridges",
                "Coastal Erosion and Shoreline Management"
            ],
            "Natural Resources and Energy": [
                "Earth’s Resources: Renewable and Nonrenewable",
                "Fossil Fuels: Formation, Extraction, and Use",
                "Alternative Energy: Solar, Wind, Geothermal",
                "Minerals and Ores: Mining and Uses",
                "Environmental Impact and Sustainability"
            ],
            "Geologic Hazards": [
                "Earthquakes: Causes, Prediction, and Preparation",
                "Volcanic Eruptions: Monitoring and Disaster Mitigation",
                "Landslides, Mudslides, and Avalanches",
                "Tsunamis: Causes and Warning Systems",
                "Climate Change and Rising Sea Levels"
            ],
            "Environmental Geology and Climate Change": [
                "Human Impact on Earth’s Systems",
                "Pollution: Water, Air, and Land",
                "Global Climate Change: Evidence and Effects",
                "Geology’s Role in Environmental Conservation",
                "Sustainable Resource Use and Management"
            ]
        }
    },
    Genetics: {
        name: "Genetics",
        introText: "Understand how traits are inherited and explore the science of genes, DNA, and heredity in Genetics.",
        chapterTopics: {
            "Introduction to Genetics": [
                "What is Genetics?",
                "The Role of DNA and RNA",
                "Genes and Chromosomes",
                "Gregor Mendel and the Laws of Inheritance"
            ],
            "Mendelian Genetics": [
                "Dominant and Recessive Traits",
                "Punnett Squares",
                "Monohybrid and Dihybrid Crosses",
                "Law of Segregation and Independent Assortment"
            ],
            "Molecular Genetics": [
                "Structure of DNA",
                "DNA Replication",
                "Transcription and Translation",
                "Mutations and DNA Repair"
            ],
            "Genetic Inheritance": [
                "Types of Inheritance: Autosomal, X-Linked",
                "Incomplete Dominance and Codominance",
                "Polygenic Inheritance",
                "Multiple Alleles and Blood Types"
            ],
            "Genetic Engineering and Biotechnology": [
                "CRISPR and Gene Editing",
                "Cloning and Stem Cells",
                "Genetically Modified Organisms (GMOs)",
                "Gene Therapy"
            ],
            "Human Genetics": [
                "Human Chromosomes and Karyotypes",
                "Sex-Linked Inheritance",
                "Genetic Disorders",
                "Ethical Issues in Human Genetics"
            ],
            "Population Genetics": [
                "Hardy-Weinberg Principle",
                "Gene Pool and Genetic Drift",
                "Natural Selection and Evolution",
                "Speciation and Genetic Diversity"
            ]
        }
    },

    Biotechnology: {
        name: "Biotechnology",
        introText: "Biotechnology explores the application of biological systems and organisms to develop innovative solutions in medicine, agriculture, and industry.",
        chapterTopics: {
          "Introduction to Biotechnology": [
            "What is Biotechnology?",
            "History and Development of Biotechnology",
            "Applications of Biotechnology in Society",
            "Ethics in Biotechnology"
          ],
          "Genetic Engineering": [
            "Recombinant DNA Technology",
            "CRISPR and Gene Editing",
            "Gene Cloning",
            "Transgenic Organisms"
          ],
          "Biotechnology in Medicine": [
            "Gene Therapy",
            "Pharmaceutical Biotechnology",
            "Vaccines and Biologics",
            "Stem Cell Research"
          ],
          "Biotechnology in Agriculture": [
            "Genetically Modified Organisms (GMOs)",
            "Biopesticides and Biofertilizers",
            "Plant Tissue Culture",
            "Agricultural Biotechnology Innovations"
          ],
          "Industrial Biotechnology": [
            "Bioreactors and Fermentation",
            "Enzymes in Industry",
            "Biofuels and Bioplastics",
            "Biotechnology for Environmental Sustainability"
          ],
          "Bioinformatics": [
            "Introduction to Bioinformatics",
            "Genome Sequencing and Databases",
            "Proteomics",
            "Applications of Bioinformatics in Research"
          ],
          "Ethics and Regulation in Biotechnology": [
            "Intellectual Property Rights",
            "Regulation of Biotechnology Products",
            "Public Perception of Biotechnology",
            "Biotechnology and Environmental Concerns"
          ]
        }
      }
      

};

export default subjectData;
