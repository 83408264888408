// src/components/HomePage.js
import React from 'react';
import { Link } from 'react-router-dom';
import './shared-styles.css';

const MathPage = () => {
  return (
    <div className="homepage-container">
      <header className="header">
        <h1>Welcome to Edu 2U</h1>
        <p>Unlock your potential with AI-driven solutions, transforming learning, creativity, and productivity for students and professionals.</p>
      </header>

      <section className="tools-section">
        <div className="tool-block">
          <div className="tool-icon">🔢</div> {/* Pre-Algebra Icon */}
          <h2>Pre-Algebra</h2>
          <p>Start your math journey with the foundational concepts of arithmetic, integers, fractions, and simple equations.</p>
          <Link to="/subject/PreAlgebra" className="tool-link">Pre-Algebra</Link>
        </div>

        <div className="tool-block">
          <div className="tool-icon">📘</div> {/* Algebra 1 Icon */}
          <h2>Algebra 1</h2>
          <p>Master Algebra 1 with concepts such as equations, inequalities, functions, and basic graphing.</p>
          <Link to="/subject/Algebra1" className="tool-link">Algebra 1</Link>
        </div>

        <div className="tool-block">
          <div className="tool-icon">📐</div> {/* Geometry Icon */}
          <h2>Geometry</h2>
          <p>Explore spatial reasoning and geometric proofs with topics such as angles, triangles, circles, and more.</p>
          <Link to="/subject/Geometry" className="tool-link">Geometry</Link>
        </div>

        <div className="tool-block">
          <div className="tool-icon">📙</div> {/* Algebra 2 Icon */}
          <h2>Algebra 2</h2>
          <p>Continue your algebra journey with advanced functions, polynomials, logarithms, and complex numbers in Algebra 2.</p>
          <Link to="/subject/Algebra2" className="tool-link">Algebra 2</Link>
        </div>
      </section>

      <section className="tools-section">
        <div className="tool-block">
          <div className="tool-icon">📏</div> {/* Precalculus Icon */}
          <h2>Precalculus</h2>
          <p>Bridge the gap between Algebra and Calculus by mastering topics like sequences, series, and an introduction to limits in Precalculus.</p>
          <Link to="/subject/Precalculus" className="tool-link">Precalculus</Link>
        </div>

        <div className="tool-block">
          <div className="tool-icon">🧮</div> {/* Calculus Icon */}
          <h2>Calculus</h2>
          <p>Delve into advanced topics like limits, derivatives, integrals, and their real-world applications in Calculus.</p>
          <Link to="/subject/Calculus" className="tool-link">Calculus</Link>
        </div>

        <div className="tool-block">
          <div className="tool-icon">📈</div> {/* Statistics Icon */}
          <h2>Statistics</h2>
          <p>Learn how to analyze data, calculate probability, and apply statistical methods to real-world problems.</p>
          <Link to="/subject/Statistics" className="tool-link">Statistics</Link>
        </div>

        <div className="tool-block">
          <div className="tool-icon">🧩</div> {/* Linear Algebra Icon */}
          <h2>Linear Algebra</h2>
          <p>Understand vector spaces, matrices, and linear transformations in this critical subject used in many advanced fields.</p>
          <Link to="/subject/LinearAlgebra" className="tool-link">Linear Algebra</Link>
        </div>
      </section>

      <section className="tools-section">
        <div className="tool-block">
          <div className="tool-icon">💻</div> {/* Discrete Math Icon */}
          <h2>Discrete Math</h2>
          <p>Learn about set theory, logic, graph theory, and algorithms. Perfect for those interested in computer science.</p>
          <Link to="/subject/DiscreteMath" className="tool-link">Discrete Mathematics</Link>
        </div>

        <div className="tool-block">
          <div className="tool-icon">🔢</div> {/* Number Theory Icon */}
          <h2>Number Theory</h2>
          <p>Explore the world of integers, prime numbers, modular arithmetic, and cryptography. Ideal for math enthusiasts.</p>
          <Link to="/subject/NumberTheory" className="tool-link">Number Theory</Link>
        </div>
        <div className="tool-block">
          <div className="tool-icon"></div> {/* Quiz Master Icon */}

        </div>

        <div className="tool-block">
          <div className="tool-icon"></div> {/* Resume vs JD Icon */}

        </div>
      </section>
    </div>
  );
};

export default MathPage;
