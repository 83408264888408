// src/components/ITPage.js
import React from 'react';
import { Link } from 'react-router-dom';
import './shared-styles.css';

const ITPage = () => {
  return (
    <div className="homepage-container">
      <header className="header">
        <h1>Welcome to Edu 2U</h1>
        <p>Explore the world of Information Technology, from networking to cybersecurity, and cloud to system administration.</p>
      </header>

      <section className="tools-section">
        <div className="tool-block">
          <div className="tool-icon">🌐</div> {/* Networking Icon */}
          <h2>Networking</h2>
          <p>Learn the fundamentals of computer networking, including LAN, WAN, TCP/IP, and more.</p>
          <Link to="/subject/Networking" className="tool-link">Explore Networking</Link>
        </div>

        <div className="tool-block">
          <div className="tool-icon">☁️</div> {/* Cloud Computing Icon */}
          <h2>Cloud Computing</h2>
          <p>Dive into cloud technologies, covering AWS, Azure, Google Cloud, and virtualization concepts.</p>
          <Link to="/subject/CloudComputing" className="tool-link">Explore Cloud Computing</Link>
        </div>

        <div className="tool-block">
          <div className="tool-icon">🔐</div> {/* Cybersecurity Icon */}
          <h2>Cybersecurity</h2>
          <p>Understand the principles of securing networks, systems, and data from cyber threats.</p>
          <Link to="/subject/Cybersecurity" className="tool-link">Explore Cybersecurity</Link>
        </div>

        <div className="tool-block">
          <div className="tool-icon">🖥️</div> {/* System Administration Icon */}
          <h2>System Administration</h2>
          <p>Learn about server management, operating systems, and system administration best practices.</p>
          <Link to="/subject/SystemAdministration" className="tool-link">Explore System Administration</Link>
        </div>
      </section>

      <section className="tools-section">
        <div className="tool-block">
          <div className="tool-icon">🔧</div> {/* IT Support Icon */}
          <h2>IT Support</h2>
          <p>Get introduced to IT support skills, troubleshooting, hardware, and software maintenance.</p>
          <Link to="/subject/ITSupport" className="tool-link">Explore IT Support</Link>
        </div>

        <div className="tool-block">
          <div className="tool-icon">🛠️</div> {/* DevOps Icon */}
          <h2>DevOps</h2>
          <p>Explore DevOps practices, CI/CD, infrastructure automation, and monitoring.</p>
          <Link to="/subject/DevOps" className="tool-link">Explore DevOps</Link>
        </div>

        <div className="tool-block">
          <div className="tool-icon">⚙️</div> {/* Virtualization Icon */}
          <h2>Virtualization</h2>
          <p>Understand virtualization technologies like VMware, Hyper-V, and containerization with Docker and Kubernetes.</p>
          <Link to="/subject/Virtualization" className="tool-link">Explore Virtualization</Link>
        </div>

        <div className="tool-block">
          <div className="tool-icon">📶</div> {/* IoT Icon */}
          <h2>Internet of Things (IoT)</h2>
          <p>Discover how IoT devices connect and interact with each other over networks, impacting industries worldwide.</p>
          <Link to="/subject/IoT" className="tool-link">Explore IoT</Link>
        </div>
      </section>
    </div>
  );
};

export default ITPage;
