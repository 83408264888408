// src/components/SoftwarePage.js
import React from 'react';
import { Link } from 'react-router-dom';
import './shared-styles.css';
const SoftwarePage = () => {
  return (
    <div className="homepage-container">
      <header className="header">
        <h1>Welcome to Edu 2U</h1>
        <p>Unlock your potential with AI-driven solutions, transforming learning, creativity, and productivity for students and professionals.</p>
      </header>

      <section className="tools-section">
        <div className="tool-block">
          <div className="tool-icon">💻</div> {/* Programming Icon */}
          <h2>Programming Basics</h2>
          <p>Learn the fundamentals of coding with languages like Python, JavaScript, and more.</p>
          <Link to="/subject/ProgrammingBasics" className="tool-link">Programming Basics</Link>
        </div>

        <div className="tool-block">
          <div className="tool-icon">📱</div> {/* Mobile Development Icon */}
          <h2>Mobile App Development</h2>
          <p>Master the skills needed to develop mobile applications for iOS and Android platforms.</p>
          <Link to="/subject/MobileDevelopment" className="tool-link">Mobile App Development</Link>
        </div>

        <div className="tool-block">
          <div className="tool-icon">🌐</div> {/* Web Development Icon */}
          <h2>Web Development</h2>
          <p>Explore web technologies including HTML, CSS, JavaScript, and modern frameworks like React and Angular.</p>
          <Link to="/subject/WebDevelopment" className="tool-link">Web Development</Link>
        </div>

        <div className="tool-block">
          <div className="tool-icon">🛠️</div> {/* Software Engineering Icon */}
          <h2>Software Engineering</h2>
          <p>Understand the process of designing, developing, and maintaining reliable software systems.</p>
          <Link to="/subject/SoftwareEngineering" className="tool-link">Software Engineering</Link>
        </div>
      </section>

      <section className="tools-section">
        <div className="tool-block">
          <div className="tool-icon">📊</div> {/* Data Structures Icon */}
          <h2>Data Structures & Algorithms</h2>
          <p>Learn essential concepts like arrays, trees, graphs, and sorting algorithms, crucial for software efficiency.</p>
          <Link to="/subject/DataStructures" className="tool-link">Data Structures & Algorithms</Link>
        </div>

        <div className="tool-block">
          <div className="tool-icon">☁️</div> {/* Cloud Computing Icon */}
          <h2>Cloud Computing</h2>
          <p>Dive into cloud platforms like AWS, Azure, and GCP, and explore services such as storage, computing, and networking.</p>
          <Link to="/subject/CloudComputing" className="tool-link">Cloud Computing</Link>
        </div>

        <div className="tool-block">
          <div className="tool-icon">🔒</div> {/* Cybersecurity Icon */}
          <h2>Cybersecurity</h2>
          <p>Discover the principles of protecting systems and networks from cyber threats and attacks.</p>
          <Link to="/subject/Cybersecurity" className="tool-link">Cybersecurity</Link>
        </div>

        <div className="tool-block">
          <div className="tool-icon">🖥️</div> {/* System Administration Icon */}
          <h2>System Administration</h2>
          <p>Understand how to manage, configure, and troubleshoot computer systems and networks.</p>
          <Link to="/subject/SystemAdministration" className="tool-link">System Administration</Link>
        </div>
      </section>

      <section className="tools-section">
        <div className="tool-block">
          <div className="tool-icon">📈</div> {/* Data Science Icon */}
          <h2>Data Science</h2>
          <p>Learn how to analyze, visualize, and make predictions from data using tools like Python, R, and machine learning.</p>
          <Link to="/subject/DataScience" className="tool-link">Data Science</Link>
        </div>

        <div className="tool-block">
          <div className="tool-icon">🤖</div> {/* AI and Machine Learning Icon */}
          <h2>AI & Machine Learning</h2>
          <p>Explore the world of AI, from machine learning algorithms to neural networks and deep learning.</p>
          <Link to="/subject/AIMachineLearning" className="tool-link">AI & Machine Learning</Link>
        </div>

        <div className="tool-block">
          <div className="tool-icon">🛡️</div> {/* DevOps Icon */}
          <h2>DevOps</h2>
          <p>Learn how to streamline development and operations processes using tools like Docker, Kubernetes, and CI/CD pipelines.</p>
          <Link to="/subject/DevOps" className="tool-link">DevOps</Link>
        </div>

        <div className="tool-block">
          <div className="tool-icon">📂</div> {/* Database Management Icon */}
          <h2>Database Management</h2>
          <p>Understand database design, management, and optimization using SQL and NoSQL technologies.</p>
          <Link to="/subject/DatabaseManagement" className="tool-link">Database Management</Link>
        </div>
      </section>
    </div>
  );
};

export default SoftwarePage;
