// PdfToWord.js
import React, { useState } from "react";
import axios from "axios";
import './PdfToWord.css';

const PdfToWord = () => {
  const [file, setFile] = useState(null);
  const [downloadUrl, setDownloadUrl] = useState("");
  const [isUploading, setIsUploading] = useState(false);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    setDownloadUrl(""); // Clear previous download link when new file is selected
  };

  const handleUpload = async () => {
    if (!file) {
      alert("Please select a file first!");
      return;
    }
    
    setIsUploading(true);
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post("https://pdf-to-word-6be1128fd799.herokuapp.com/upload", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      setDownloadUrl(`https://pdf-to-word-6be1128fd799.herokuapp.com/${response.data.download_url}`);
    } catch (error) {
      console.error("Upload failed:", error);
      alert("Failed to upload file. Please try again.");
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <div className="container">
      <h1>PDF to Word</h1>
      <div className="upload-box">
        <input type="file" id="fileInput" onChange={handleFileChange} accept=".pdf" />
        <label htmlFor="fileInput" className="file-label">
          {file ? file.name : "Choose a PDF file"}
        </label>
        <button onClick={handleUpload} disabled={isUploading}>
          {isUploading ? "Uploading..." : "Convert PDF"}
        </button>
      </div>
      {downloadUrl && (
        <div className="download-link">
          <a href={downloadUrl} download>
            Download Word Document
          </a>
        </div>
      )}
    </div>
  );
};

export default PdfToWord;
